import { find, sortBy } from 'lodash-es'
import { $t, transformI18n } from '@/i18n/index'
import { useGlobalStore } from '@/stores/modules/global'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { HeaderMenuMainDataItemType, NavigationType, MenuMainDataItemType } from '#/MenuTypes'

export const useMenuGlobalStore = defineStore({
  id: 'useMenuGlobalStore',
  state: () => {
    const menuGlobalState: {
      menuMainData: MenuMainDataItemType[]
      menuChooseId: string
      menuChooseFatherName: string
      isOpenState: boolean
      isFixedMenu: boolean
      pageNavWidth: number
      mainColor: string
    } = {
      mainColor: '#2d62eb',
      isOpenState: true,
      isFixedMenu: true,
      menuChooseId: '',
      menuChooseFatherName: '',
      pageNavWidth: 100,
      //   前端拼的导航数据
      menuMainData: []
    }
    return menuGlobalState
  },

  actions: {
    /**
     * @description: 修改色值
     * @param {string} params
     * @return {*}
     */
    changeMainColor(params: string) {
      this.mainColor = params
    },

    /**
     * @description: Menu选项改变
     * @param {string} val
     * @return {*}
     */
    changeMenuChooseId(val: string) {
      this.menuChooseId = val
    },

    /**
     * @description: Menu父级选项改变
     * @param {string} val
     * @return {*}
     */
    changeChooseFatherMenuName(val: string) {
      this.menuChooseFatherName = val
    },

    /** 导航改变开关状态
     * @description:
     * @param {boolean} val
     * @return {*}
     */
    changeMenuOpen(val: boolean) {
      this.isOpenState = val
    },

    /**
     * @description: 导航改变固定状态
     * @param {boolean} val
     * @return {*}
     */
    changeMenuFixed(val: boolean) {
      this.isFixedMenu = val
    },

    // 改变页面中nav的宽度
    changePageNavWidth() {
      let leftMenuWidth = 48
      if (this.isFixedMenu) {
        leftMenuWidth = 180
      }
      const nameWidth = document.getElementById('navName')?.offsetWidth ?? 100
      const allWidth = document.documentElement.clientWidth || document.body.clientWidth
      this.pageNavWidth = allWidth - leftMenuWidth - nameWidth - 160
    }
  },
  getters: {
    // 导航数据处理
    // eslint-disable-next-line no-unused-vars
    menuMainDataAll: (state) => {
      const userGlobalStore = useUserGlobalStore()
      const globalStore = useGlobalStore()
      const { isLogin, user } = storeToRefs(userGlobalStore)
      let sortedProject: any = []
      if (user.value?.navigationSort?.length) {
        sortedProject = sortBy(globalStore.projectList, (item) => {
          const sortOrderItem = find(user.value?.navigationSort, { projectId: item.projectId })
          return sortOrderItem ? user.value?.navigationSort.indexOf(sortOrderItem) : 999999
        })
        sortedProject.forEach((item: any) => {
          const sortOrderItem: any = find(user.value?.navigationSort, { projectId: item.projectId })
          if (sortOrderItem) {
            item.entityList = sortBy(item.entityList, (entity) => {
              const sortOrderEntity = sortOrderItem.entityIds.indexOf(entity.entityId)
              return sortOrderEntity !== -1 ? sortOrderEntity : 999999
            })
          }
        })
      }
      const userProjectList = user.value?.navigationSort?.length ? sortedProject : globalStore.projectList
      const myProjects: { menuName: string; menuIcon: string; children: any }[] = []
      if (isLogin.value && userProjectList.length) {
        userProjectList?.forEach((item: any) => {
          if (item.plan === 'BASIC' || item.plan === 'FREE') {
            const entityPermission = find(user.value?.entityMemberList, { projectId: item.projectId })?.permission
            const childrenMenus: any = []
            if (entityPermission?.dataSourceView) {
              childrenMenus.push({
                jumpUrl: `/reports/${item.entityList[0].entityId}/sources`,
                id: `${item.entityList[0].name}${item.projectId}Sources`,
                projectName: item.name,
                projectId: item.projectId,
                entityId: item.entityList[0].entityId,
                requiresAuth: true,
                line: false,
                soon: false,
                name: transformI18n($t('menus.sources')),
                menuName: item.entityList[0].name // 每一项添加其父级‘menuName’
              })
            }
            if (entityPermission?.transactionViewList) {
              childrenMenus.push({
                jumpUrl: `/reports/${item.entityList[0].entityId}/transactions`,
                id: `${item.entityList[0].name}${item.projectId}Transactions`,
                projectName: item.name,
                projectId: item.projectId,
                entityId: item.entityList[0].entityId,
                requiresAuth: true,
                line: false,
                soon: false,
                name: transformI18n($t('menus.transactions')),
                menuName: item.entityList[0].name // 每一项添加其父级‘menuName’
              })
            }
            if (entityPermission?.reportView) {
              childrenMenus.push({
                jumpUrl: `/reports/${item.entityList[0].entityId}/balance-sheet`,
                id: `${item.entityList[0].name}${item.projectId}Reports`,
                projectName: item.name,
                projectId: item.projectId,
                entityId: item.entityList[0].entityId,
                requiresAuth: true,
                line: false,
                soon: false,
                name: transformI18n($t('menus.reports')),
                menuName: item.entityList[0].name // 每一项添加其父级‘menuName’
              })
            }
            if (entityPermission?.generalLedgerView) {
              childrenMenus.push({
                jumpUrl: `/reports/${item.entityList[0].entityId}/general-ledger`,
                id: `${item.entityList[0].name}${item.projectId}Ledger`,
                projectName: item.name,
                projectId: item.projectId,
                entityId: item.entityList[0].entityId,
                requiresAuth: true,
                line: false,
                soon: false,
                name: transformI18n($t('menus.ledger')),
                menuName: item.entityList[0].name // 每一项添加其父级‘menuName’
              })
            }
            if (entityPermission?.contactView) {
              childrenMenus.push({
                jumpUrl: `/reports/${item.entityList[0].entityId}/contacts`,
                id: `${item.entityList[0].name}${item.projectId}Contacts`,
                projectName: item.name,
                projectId: item.projectId,
                entityId: item.entityList[0].entityId,
                requiresAuth: true,
                line: false,
                soon: false,
                name: transformI18n($t('menus.contacts')),
                menuName: item.entityList[0].name // 每一项添加其父级‘menuName’
              })
            }
            if (entityPermission?.configRuleView) {
              childrenMenus.push({
                jumpUrl: `/reports/${item.entityList[0].entityId}/rules`,
                id: `${item.entityList[0].name}${item.projectId}Automation`,
                projectName: item.name,
                projectId: item.projectId,
                entityId: item.entityList[0].entityId,
                requiresAuth: true,
                line: false,
                soon: false,
                name: transformI18n($t('menus.automation')),
                menuName: item.entityList[0].name // 每一项添加其父级‘menuName’
              })
            }
            if (item.creatorId === user.value?.userId) {
              childrenMenus.push({
                jumpUrl: `/project/${item.entityList[0].projectId}/settings`,
                id: `${item.entityList[0].name}${item.projectId}Settings`,
                projectName: item.name,
                projectId: item.projectId,
                entityId: item.entityList[0].entityId,
                requiresAuth: true,
                line: false,
                soon: false,
                name: transformI18n($t('menus.settings')),
                menuName: item.entityList[0].name // 每一项添加其父级‘menuName’
              })
            }
            const project = {
              menuName: item.entityList[0].name,
              menuIcon: 'menu-my-projects',
              plan: item.plan,
              entityId: item.entityList[0].entityId,
              children: childrenMenus
            }
            if (childrenMenus.length) {
              myProjects.push(project)
            }
          } else {
            const secondaryMenu: any = []
            if (item?.entityList && item?.entityList.length) {
              item?.entityList?.forEach((entity: any) => {
                const entityPermission = find(user.value?.entityMemberList, {
                  entityId: entity.entityId
                })?.permission
                const thirdLevelMenu = []
                if (entityPermission?.dataSourceView) {
                  thirdLevelMenu.push({
                    jumpUrl: `/reports/${entity.entityId}/sources`,
                    id: `${entity.name}${entity.projectId}Sources`,
                    projectName: item.name,
                    entityName: entity.name,
                    projectId: entity.projectId,
                    entityId: entity.entityId,
                    requiresAuth: true,
                    line: false,
                    soon: false,
                    name: transformI18n($t('menus.sources')),
                    menuName: entity.name // 每一项添加其父级‘menuName’
                  })
                }
                if (entityPermission?.transactionViewList) {
                  thirdLevelMenu.push({
                    jumpUrl: `/reports/${entity.entityId}/transactions`,
                    id: `${entity.name}${entity.projectId}Transactions`,
                    projectName: item.name,
                    entityName: entity.name,
                    projectId: entity.projectId,
                    entityId: entity.entityId,
                    requiresAuth: true,
                    line: false,
                    soon: false,
                    name: transformI18n($t('menus.transactions')),
                    menuName: entity.name // 每一项添加其父级‘menuName’
                  })
                }
                if (entityPermission?.reportView) {
                  thirdLevelMenu.push({
                    jumpUrl: `/reports/${entity.entityId}/balance-sheet`,
                    id: `${entity.name}${entity.projectId}Reports`,
                    projectName: item.name,
                    entityName: entity.name,
                    projectId: entity.projectId,
                    entityId: entity.entityId,
                    requiresAuth: true,
                    line: false,
                    soon: false,
                    name: transformI18n($t('menus.reports')),
                    menuName: entity.name // 每一项添加其父级‘menuName’
                  })
                }
                if (entityPermission?.generalLedgerView) {
                  thirdLevelMenu.push({
                    jumpUrl: `/reports/${entity.entityId}/general-ledger`,
                    id: `${entity.name}${entity.projectId}Ledger`,
                    projectName: item.name,
                    entityName: entity.name,
                    projectId: entity.projectId,
                    entityId: entity.entityId,
                    requiresAuth: true,
                    line: false,
                    soon: false,
                    name: transformI18n($t('menus.ledger')),
                    menuName: entity.name // 每一项添加其父级‘menuName’
                  })
                }
                if (entityPermission?.contactView) {
                  thirdLevelMenu.push({
                    jumpUrl: `/reports/${entity.entityId}/contacts`,
                    id: `${entity.name}${entity.projectId}Contacts`,
                    projectName: item.name,
                    entityName: entity.name,
                    projectId: entity.projectId,
                    entityId: entity.entityId,
                    requiresAuth: true,
                    line: false,
                    soon: false,
                    name: transformI18n($t('menus.contacts')),
                    menuName: entity.name // 每一项添加其父级‘menuName’
                  })
                }
                if (entityPermission?.configRuleView) {
                  thirdLevelMenu.push({
                    jumpUrl: `/reports/${entity.entityId}/rules`,
                    id: `${entity.name}${entity.projectId}Automation`,
                    projectName: item.name,
                    entityName: entity.name,
                    entityId: entity.entityId,
                    projectId: entity.projectId,
                    requiresAuth: true,
                    line: false,
                    soon: false,
                    name: transformI18n($t('menus.automation')),
                    menuName: entity.name // 每一项添加其父级‘menuName’
                  })
                }
                const secondary = {
                  projectId: entity.projectId,
                  entityId: entity.entityId,
                  menuName: entity.name,
                  children: thirdLevelMenu
                }
                secondaryMenu.push(secondary)
              })
            }
            const project = {
              menuName: item.name,
              menuIcon: 'menu-my-projects',
              plan: item.plan,
              projectId: item.projectId,
              creatorId: item.creatorId,
              children: secondaryMenu
            }

            myProjects.push(project)
          }
        })
      }
      const menuDataAll = [...myProjects]
      const data = { menuDataAll }
      return data
    },
    headerMainDataAll() {
      const menuMainDataAllFilter = this.menuMainDataAll?.menuDataAll.filter((item: MenuMainDataItemType) => {
        return item.menuName !== 'My Screener'
      })
      const mainDataAll: HeaderMenuMainDataItemType[] = menuMainDataAllFilter.map((item: MenuMainDataItemType) => {
        const childrenNoSoon = item.children.filter((itemChildren: NavigationType) => {
          return itemChildren.soon !== true
        })
        const itemVal = {
          ...item,
          childrenNum: item.children.length,
          childrenNoSoon,
          jumpUrl: childrenNoSoon.length ? childrenNoSoon[0].jumpUrl : ''
        }
        return itemVal
      })

      const data = { mainDataAll }
      return data
    },

    // 跳转到第一个页面的Url
    firstPageUrl() {
      const jumpUrl: string = this.headerMainDataAll?.mainDataAll[0]?.jumpUrl
      return jumpUrl
    }
  }
})
